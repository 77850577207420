import api from "!../../../../../../tmp/drone/cache/0d9f5e4e8e78f2ed8e7ee839c446a436a3364e5edea7204a11e114b8a771140c/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../tmp/drone/cache/0d9f5e4e8e78f2ed8e7ee839c446a436a3364e5edea7204a11e114b8a771140c/node_modules/css-loader/index.js??ruleSet[1].rules[1].use[1]!./yellowHiveCss.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};