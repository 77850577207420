type Dict = Record<string, string>;

const parts = ['container', 'title', 'description', 'icon'];

const baseStyle = {
  container: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '5px',
    p: 6,
  },
  title: {
    fontWeight: 400,
    lineHeight: 6,
    marginEnd: 2,
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    lineHeight: 6,
  },
  icon: {
    flexShrink: 0,
    marginEnd: 4,
    w: 5,
    h: 6,
  },
};

function variantSubtle(props: Dict): {
  container: Record<string, string>;
  description: Record<string, string>;
  title: Record<string, string>;
  icon?: Record<string, string>;
} {
  const { status } = props;
  switch (status) {
    case 'info':
      return {
        container: {
          p: '4',
          bg: 'info.light',
          borderColor: 'info.main',
        },
        description: {
          color: 'info.main',
        },
        title: {
          color: 'info.main',
          fontWeight: 'bold',
        },
      };

    case 'warning':
      return {
        container: {
          p: '4',
          bg: 'warning.light',
          borderColor: 'warning.main',
        },
        description: {
          color: 'warning.main',
        },
        title: {
          color: 'warning.main',
        },
        icon: {
          color: 'warning.main',
        },
      };
    case 'success':
      return {
        container: {
          p: '4',
          bg: 'success.light',
          borderColor: 'success.main',
        },
        description: {
          color: 'success.dark',
        },
        title: {
          color: 'success.dark',
        },
      };
    case 'error':
      return {
        container: {
          p: '4',
          bg: 'danger.light',
          borderColor: 'danger.main',
        },
        description: {
          color: 'danger.main',
        },
        title: {
          color: 'danger.main',
        },
      };
    default:
      return {
        container: {},
        title: {},
        description: {},
      };
  }
}

const variants = {
  subtle: variantSubtle,
  gray: {
    container: {
      p: '4',
      bg: 'gray.ultraLight',
      borderColor: 'gray.100',
    },
    description: {
      color: 'gray.500',
    },
    title: {
      color: 'gray.500',
    },
    icon: {
      color: 'gray.500',
    },
  },
  ai: {
    container: {
      p: '4',
      bg: '#F9F5FF',
      borderColor: '#6941C6',
      color: '#6941C6',
    },
    description: {
      color: '#6941C6',
    },
    title: {
      fontWeight: '500',
      color: '#6941C6',
    },
    icon: {
      color: '#6941C6',
    },
  },
  warning_new: {
    container: {
      p: '2',
      pl: '3',
      pr: '3',
      bg: '#FDF6E7',
      borderColor: 'rgba(0,0,0,0)',
    },
    description: {
      color: '#F5CD79',
    },
    title: {
      color: '#F0B638',
    },
    icon: {
      color: '#F0B638',
      marginEnd: 3,
    },
  },
};

const alert = {
  parts,
  baseStyle,
  variants,
};

export default alert;
